import { ValueWithLabel, getLabelsFromTokens } from "../util";
import {
    Box,
    Button,
    Checkbox,
    ColumnLayout,
    Form,
    Grid,
    Input,
    Link,
    Multiselect, Popover,
    SpaceBetween,
    TokenGroup
} from "@amzn/awsui-components-react";
import React from "react";
import { getDeviceObjsAll, getDeviceObjsOptions } from "../../features/device/devicesUtil"
import { cvePattern } from "../../utils/Constants";
import StatusBar from "../../commons/statusBar";
import { createNewReport } from "../../features/scan/createReport";
import { useNavigate } from "react-router-dom";

export function CveDeviceTab({ devices }) {
    const navigate = useNavigate();
    const [cveText, setCveText] = React.useState("");
    const [cveTokens, setCveTokens] = React.useState([])
    const [selectedDevices, setSelectedDevices] = React.useState([])
    const [cachedResults, setCachedResults] = React.useState(true);
    const [previousAll, setPreviousAll] = React.useState(false);
    const [submitBtnDisabled, setSubmitBtnDisabled] = React.useState(true)
    const [submitBtnLoading, setSubmitBtnLoading] = React.useState(false);
    const [statusMsgObj, setStatusMsgObj] = React.useState(null);


    const onAdd = () => {
        if (cveText.length === 0) {
            return;
        }
        const cves = cveText.split(",");
        for (const cve of cves) {
            if (!cvePattern.test(cve)) {
                setStatusMsgObj({ 'type': 'error', message: `'${cve}' is NOT valid. Please enter a valid CVE` });
                return;
            }
        }
        const addedCves = new Set(cveTokens.map(item => item['label']));
        let newCveTokens = [...cveTokens];
        console.log(cves.filter(cve => !addedCves.has(cve)))
        cves.filter(cve => !addedCves.has(cve)).forEach(cve => { newCveTokens = newCveTokens.concat({ label: cve }) }) //dedup
        setSubmitBtnDisabled(newCveTokens.length === 0 || selectedDevices.length === 0);
        setCveTokens(newCveTokens);
    }

    const onSubmit = async () => {
        setSubmitBtnLoading(true);
        try {
            await createNewReport({ cveIds: getLabelsFromTokens(cveTokens), devices: getLabelsFromTokens(selectedDevices), freshScan: !cachedResults })
        } catch (e) {
            console.log(e)
            setStatusMsgObj({ 'type': 'error', message: `Report creation failed: ${e}` })
            return;
        } finally {
            setSubmitBtnLoading(false);
        }
        setCveText("");
        setCveTokens([]);
        setSelectedDevices([]);
        setPreviousAll(false);
        setStatusMsgObj({
            'type': 'success', message: "Report created successfully.",
            action: <Link variant="primary" href="/myReport" onFollow={(event) => { event.preventDefault(); navigate("/myReport") }}><Button>View Report</Button></Link>
        })
    }
    return (
        <Box>
            <SpaceBetween size="l">
                <StatusBar statusMsgObj={statusMsgObj} />
                <Box >Input Linux Kernel's CVE and select Devices set you are interested in; This will scan the vulnerability's patch applicablity to Devices' kernel source code</Box>
                <Form
                    actions={
                        <SpaceBetween direction={"horizontal"} size={"xxl"}>
                            <SpaceBetween direction={"horizontal"} size={"xxxs"}>
                                <Checkbox className={"awsui_button_vjswe_1im18_101 awsui_variant-icon_vjswe_1im18_170"} checked={cachedResults} onChange={({ detail }) => setCachedResults(detail.checked)}>
                                    Retrieve cached PANN results from 30 days (recommended)
                                </Checkbox>
                                <Popover triggerType="custom" position="bottom" content={"This will fetch PANN results from the last 30 days scan if any. Uncheck this option if you want to force a fresh scan without relying on cached results. Unchecking may take longer time."}>
                                    <Button iconName="status-info" variant="icon" />
                                </Popover>
                            </SpaceBetween>
                            <Button variant="primary" disabled={submitBtnDisabled} loading={submitBtnLoading} onClick={() => onSubmit()}>Submit</Button>
                        </SpaceBetween>
                    }
                >
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel label="CVEs">
                                <Grid
                                    gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
                                >
                                    <Input
                                        onChange={({ detail }) => {
                                            setCveText(detail.value.replaceAll(/\s/g, '').toUpperCase())
                                        }}
                                        onBlur={({ detail }) => {
                                            onAdd();
                                        }}
                                        value={cveText}
                                        placeholder="CVE-2020-10757,CVE-2021-1479"
                                        onKeyDown={(event) => event.detail.key === 'Enter' && onAdd()}

                                    />
                                    <Button iconName="add-plus"
                                        onClick={() => onAdd()}
                                    />
                                </Grid>
                            </ValueWithLabel>
                            <TokenGroup
                                onDismiss={({ detail: { itemIndex } }) => {
                                    setCveTokens([
                                        ...cveTokens.slice(0, itemIndex),
                                        ...cveTokens.slice(itemIndex + 1)
                                    ]);
                                }}
                                items={cveTokens}
                            />
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel label="Devices">
                                <Multiselect
                                    selectedOptions={selectedDevices}
                                    onChange={({ detail }) => {
                                        let selectedOptions = detail.selectedOptions;
                                        // find any item with "all" value
                                        let allIndex = selectedOptions.findIndex(item => item.value === "*");
                                        let currentAllSelected = allIndex > -1;

                                        if (previousAll) {
                                            if (currentAllSelected) {
                                                if (selectedOptions.length < getDeviceObjsAll(devices).length + 1) {
                                                    setSelectedDevices(selectedOptions.filter(item => item.value !== "*"));
                                                    setPreviousAll(false);
                                                } else {
                                                    setSelectedDevices([{ label: "All", value: "*" }, ...getDeviceObjsAll(devices)]);
                                                    setPreviousAll(true);
                                                }
                                            } else {
                                                setSelectedDevices([])
                                                setPreviousAll(false);
                                            }
                                        } else {
                                            if (currentAllSelected) {
                                                setSelectedDevices([{ label: "All", value: "*" }, ...getDeviceObjsAll(devices)]);
                                                setPreviousAll(true);
                                            } else {
                                                if (selectedOptions.length === getDeviceObjsAll(devices).length) {
                                                    setSelectedDevices([{ label: "All", value: "*" }, ...getDeviceObjsAll(devices)]);
                                                    setPreviousAll(true);
                                                }
                                                else {
                                                    setSelectedDevices(selectedOptions);
                                                }
                                            }
                                        }
                                        setSubmitBtnDisabled(cveTokens.length === 0 || detail.selectedOptions.length === 0);
                                    }
                                    }
                                    options={[{ label: "All", value: "*" }, ...getDeviceObjsOptions(devices)]}
                                    statusType={devices ? (devices.length > 0 ? "finished" : "loading") : "error"}
                                    errorText="Unable to fetch devices"
                                    loadingText="Fetching devices"
                                    filteringType="auto"
                                    placeholder="Choose devices"
                                />
                            </ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </Form>
            </SpaceBetween>
        </Box>
    )
}