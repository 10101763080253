import { ValueWithLabel, getLabelsFromTokens } from "../util";
import {
    Button,
    Form,
    Input,
    SpaceBetween,
    TokenGroup,
    Box,
    Link,
    Grid,
    Checkbox,
    Popover, StatusIndicator
} from "@amzn/awsui-components-react";
import React from "react";
import { createNewReport } from "../../features/scan/createReport";
import StatusBar from "../../commons/statusBar";
import { jiraIdPattern } from "../../utils/Constants";
import { useNavigate } from "react-router-dom";
import {useSelector} from "react-redux";
import {selectUserAlias} from "../../features/auth/userAliasSlice";

export function TicketTab() {
    const navigate = useNavigate();
    const [jiraText, setJiraText] = React.useState("");
    const [jiraTokens, setJiraTokens] = React.useState([])
    const [cachedResults, setCachedResults] = React.useState(true);
    const [submitBtnDisabled, setSubmitBtnDisabled] = React.useState(true);
    const [submitBtnLoading, setSubmitBtnLoading] = React.useState(false);
    const [statusMsgObj, setStatusMsgObj] = React.useState(null);
    const userAlias = useSelector(selectUserAlias);

    const onAdd = () => {
        if (jiraText.trim().length === 0) {
            return;
        }
        const jiras = jiraText.split(",");
        for (const jira of jiras) {
            if (!jiraIdPattern.test(jira)) {
                setStatusMsgObj({ 'type': 'error', message: `'${jira}' is NOT valid. Please enter a valid Jira Id` });
                return;
            }
        }
        const addedJiras = new Set(jiraTokens.map(item => item['label']));
        let newJiraTokens = [...jiraTokens];
        jiras.filter(jira => !addedJiras.has(jira)).forEach(jira => { newJiraTokens = newJiraTokens.concat({ label: jira }) }) //dedup
        setSubmitBtnDisabled(newJiraTokens.length === 0);
        setJiraTokens(newJiraTokens);
    }
    
    const onSubmit = async () => {
        setSubmitBtnLoading(true);
        try {
            await createNewReport({ jiraIds: getLabelsFromTokens(jiraTokens), freshScan: !cachedResults})
        } catch (e) {
            console.log(e)
            setStatusMsgObj({'type': 'error', message: `Report creation failed: ${e}`})
            return;
        } finally {
            setSubmitBtnLoading(false);
        }
        setJiraText("");
        setJiraTokens([]);
        setStatusMsgObj({
            'type': 'success', message: "Report created successfully.",
            action: <Link variant="primary" href="/myReport" onFollow={(event) => { event.preventDefault(); navigate("/myReport") }}><Button>View Report</Button></Link>
        })
    }

    return (
        <Box>
            <SpaceBetween size='l'>
                <StatusBar statusMsgObj={statusMsgObj}/>
                <Box >Input existed Jira ids for Devices' Linux kernel vulnerability; This will automatically parse the Jira issues to Patch sets and devices affected and run applicability scan for them</Box>
                {userAlias.startsWith("ext-") && <StatusIndicator type="warning">Attention Users: Please note that you can only input Ticket Ids that you have access to. Any ticket Ids that you do not have access will not show any results.</StatusIndicator>}
                <Form
                    actions={
                    <SpaceBetween direction={"horizontal"} size={"xxl"}>
                        <SpaceBetween direction={"horizontal"} size={"xxxs"}>
                            <Checkbox className={"awsui_button_vjswe_1im18_101 awsui_variant-icon_vjswe_1im18_170"} checked={cachedResults} onChange={({ detail }) => setCachedResults(detail.checked)}>
                                Retrieve cached PANN results from 30 days (recommended)
                            </Checkbox>
                            <Popover triggerType="custom" position="bottom" content={"This will fetch PANN results from the last 30 days scan if any. Uncheck this option if you want to force a fresh scan without relying on cached results. Unchecking may take longer time."}>
                                <Button iconName="status-info" variant="icon" />
                            </Popover>
                        </SpaceBetween>
                        <Button variant="primary"
                                loading={submitBtnLoading}
                                disabled={submitBtnDisabled}
                                onClick={() => onSubmit()}>Submit</Button>
                    </SpaceBetween>

                    }
                >
                    <SpaceBetween size="l">
                        <ValueWithLabel label="Ticket System">
                            <Input value="Jira" readOnly/>
                        </ValueWithLabel>
                        <ValueWithLabel label="Ticket Id">
                            <Grid
                                gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}
                            >                                
                                <Input
                                    onChange={({ detail }) => setJiraText(detail.value.replaceAll(/\s/g, '').toUpperCase())}
                                    onBlur={({ detail }) => {onAdd()}}
                                    value={jiraText}
                                    placeholder="DEE-392942,JIRA-4321"
                                    onKeyDown={(event) => event.detail.key === 'Enter' && onAdd()}
                                />
                                <Button iconName="add-plus"
                                    onClick={() => onAdd()} />
                            </Grid>
                        </ValueWithLabel>
                        <TokenGroup
                            onDismiss={({ detail: { itemIndex } }) => {
                                setJiraTokens([
                                    ...jiraTokens.slice(0, itemIndex),
                                    ...jiraTokens.slice(itemIndex + 1)
                                ]);
                            }}
                            items={jiraTokens}
                        />
                    </SpaceBetween>
                </Form>
            </SpaceBetween>
        </Box>
    )
}